<template>
    <div class="p-lg-4 p-sm-6 p-col-12" style="text-align:left">
        <CustomMultiSelect v-model="selectedColumns" :items="columns"
                           item-label="header"
                           label="Widoczne kolumny" name="store-list"/>
    </div>
    <div class="p-lg-4 p-sm-6 p-col-12 p-m-0" style="text-align:left">
        Zaznaczonych {{ selectedStores.length }} z {{ totalRecords }} wierszy
    </div>
    <div style="overflow: auto;">
        <DataTable ref="storeTable" v-if="isMounted" :value="stores" :lazy="true"
                   :paginator="paginator"
                   v-model:rows="searchParams.page.limit" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   dataKey="id"
                   :auto-layout="true" paginatorPosition="both"
                   @sort="onSort" :rowsPerPageOptions="[5,10,20,50]"
                   :key="displayAll" scroll-direction="horizontal"
                   v-model:selection="selectedStores" @row-select-all="onSelectAll"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
                   CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Wyniki {first} do {last} z {totalRecords}">
            <Column selectionMode="multiple"
                    header="Zaznacz wiersze" headerClass="visually-hidden-header"/>
            <Column v-if="((!!collectionId && !changingStatuses) || Role.isFPBZCoordinator() || Role.isBZCoordinator())"
                    header="Akcje">
                <template #body="slotProps">
                    <div v-if="!!collectionId && invitingStores">
                        <Button type="button" icon="pi pi-plus" v-if="canAddToCollection(slotProps.data)"
                                @click="onAddStoresToCollection(slotProps.data)"
                                :label="queryType === 'OWN' ? 'Dodaj' : 'Przejmij'"/>
                        <Button type="button" icon="pi pi-minus" v-if="canRemoveFromCollection(slotProps.data)"
                                @click="onRemoveStoresFromCollection(slotProps.data)"
                                :label="queryType !== 'OWN' ? 'Cofnij prośbę' : 'Odrzuć ze zbiórki'"/>
                    </div>
                    <div v-else-if="canSeeOverlayPanel(slotProps.data)"
                         :class="'actions-' + slotProps.data.id">
                        <template v-if="BZCoordinatorCanSeeStatusHistory && !collectionId">
                            <div class="p-d-flex p-flex-column p-flex-md-row p-m-1">
                                <Button class="p-m-1" type="button" role="link"
                                        v-if="!collectionId"
                                        icon="pi pi-book" label="Historia statusów"
                                        @click="$router.push({
                                            name: 'collectionStoreStatusHistory',
                                            params: {'id': slotProps.data.id},
                                        })"/>
                            </div>
                        </template>

                        <template v-else>
                            <Button type="button" label="Akcje"
                                    @click="toggleActionsMenu($event, slotProps)"
                                    aria-haspopup="true" aria-controls="overlay_menu"
                                    :aria-expanded="actionsMenuVisible"/>
                            <OverlayPanel id="actions_menu" :ref="'actionsMenu' + slotProps.data.id"
                                          append-to=".wrapper" v-if="isMounted"
                                          @show="moveActionsMenu(slotProps.data.id)"
                                          @hide="onHideActionsMenu">
                                <div class="actionsMenu p-d-flex p-flex-column p-flex-md-row">
                                    <!-- HISTORIA ZMIAN STATUSÓW -->

                                    <div v-if="Role.isFPBZCoordinator() && !changingStatuses"
                                         class="p-m-1">
                                        <Button class="button-link" type="button" role="link"
                                                icon="pi pi-book" label="Historia statusów"
                                                @click="$router.push({
                                                    name: 'collectionStoreStatusHistory',
                                                    params: {'id': slotProps.data.id},
                                                })"/>
                                    </div>

                                    <!-- ZWYKŁY WIDOK -->
                                    <div v-if="!collectionId"
                                         class="p-d-flex p-flex-column p-flex-md-row p-m-1">
                                        <Button
                                            v-if="queryType !== 'OTHERS' && Role.isFPBZCoordinator()"
                                            class="p-m-1 button-link"
                                            type="button" role="link" icon="pi pi-pencil"
                                            label="Edycja sklepu"
                                            @click="$router.push({
                                                name: 'collectionStoreEdit',
                                                params: {'id': slotProps.data.id},
                                            })"/>
                                        <Button v-if="Role.isFPBZCoordinator()" class="p-m-1"
                                                type="button" label="Usuń sklep" icon="pi pi-minus"
                                                @click="deleteStore(slotProps.data.id, $event)">
                                        </Button>
                                    </div>

                                    <!-- WIDOK ZMIANY STATUSÓW W ZBIÓRCE -->
                                    <div v-else-if="changingStatuses"
                                         class="p-d-flex p-flex-column p-flex-md-row p-m-1">
                                        <div v-if="slotProps.data.collectionStatus === 'SIGNED_UP'"
                                             class="p-d-flex p-flex-column p-flex-md-row">
                                            <Button class="p-m-1"
                                                    type="button" icon="pi pi-plus"
                                                    label="Dodaj do zbiórki"
                                                    @click="onSetStoreCollectionStatus(slotProps.data.id,
                                                                                       'ACTIVE')"/>
                                            <Button class="p-m-1"
                                                    type="button" icon="pi pi-plus"
                                                    label="Wyślij do sieci"
                                                    @click="onSetStoreCollectionStatus(
                                                        slotProps.data.id,
                                                        'WAITING_FOR_STORE_CHAIN_DECISION')"/>
                                            <Button class="p-m-1"
                                                    type="button" icon="pi pi-minus"
                                                    label="Odrzuć ze zbiórki"
                                                    @click="onSetStoreCollectionStatus(slotProps.data.id,
                                                                                       'REJECTED')"/>
                                        </div>
                                        <div v-else-if="slotProps.data.collectionStatus
                                                 === 'WAITING_FOR_STORE_CHAIN_DECISION'"
                                             class="p-d-flex p-flex-column p-flex-md-row">
                                            <Button class="p-m-1"
                                                    type="button" icon="pi pi-plus"
                                                    label="Dodaj do zbiórki"
                                                    @click="onSetStoreCollectionStatus(slotProps.data.id, 'ACTIVE')"/>
                                            <Button class="p-m-1"
                                                    type="button" icon="pi pi-minus"
                                                    label="Odrzuć ze zbiórki"
                                                    @click="onSetStoreCollectionStatus(slotProps.data.id, 'REJECTED')"/>
                                        </div>
                                    </div>
                                </div>
                            </OverlayPanel>
                        </template>
                    </div>
                    <div v-else-if="['ACTIVE', 'RESTORED'].includes(slotProps.data.collectionStatus)">
                        <Button class="p-m-1"
                                type="button" icon="pi pi-minus" label="Odrzuć"
                                v-tooltip="'Odrzuć ze zbiórki'"
                                @click="onSetStoreCollectionStatus(slotProps.data.id, 'REJECTED')"/>
                    </div>
                    <div v-else-if="slotProps.data.collectionStatus === 'REJECTED'">
                        <Button class="p-m-1"
                                type="button" icon="pi pi-plus" label="Przywróć"
                                v-tooltip="'Przywróć do zbiórki'"
                                @click="onSetStoreCollectionStatus(slotProps.data.id, 'RESTORED')"/>
                    </div>
                </template>
            </Column>

            <Column field="collectionStatus" header="Status w zbiórce"
                    v-if="changingStatuses || (invitingStores && queryType !== 'UNASSIGNED')"
                    :sortable="false" :style="{'min-width': '160px'}">
                <template #body="slotProps">
                    <div style="margin-right: 20px;">
                        <!-- QUERYTYPE OWN -->
                        <template v-if="queryType === 'OWN' &&
                            (activeStores.has(slotProps.data.id) || invitedStores.has(slotProps.data.id))">
                            <CustomBadge v-show="slotProps.data.collectionStatus === 'SIGNED_UP' && queryType === 'OWN'"
                                         :label="storeCollectionStatusMapping[slotProps.data.collectionStatus]"
                                         :tooltip="slotProps.data.statusUpdateDate"
                                         :color="collectionStatusBadgeColor.SIGNED_UP"/>
                            <CustomBadge
                                v-show="slotProps.data.collectionStatus === 'WAITING_FOR_STORE_CHAIN_DECISION'
                                    && queryType === 'OWN'"
                                :label="storeCollectionStatusMapping[slotProps.data.collectionStatus]"
                                :tooltip="slotProps.data.statusUpdateDate"
                                :color="collectionStatusBadgeColor.WAITING_FOR_STORE_CHAIN_DECISION"/>
                            <CustomBadge v-show="(slotProps.data.collectionStatus === 'ACTIVE'
                                             || slotProps.data.collectionStatus === 'RESTORED')  && queryType === 'OWN'"
                                         :label="storeCollectionStatusMapping[slotProps.data.collectionStatus]"
                                         :tooltip="slotProps.data.statusUpdateDate"
                                         :color="collectionStatusBadgeColor.ACTIVE"/>
                        </template>
                        <template v-else>
                            <CustomBadge v-show="rejectedStores.has(slotProps.data.id) && queryType !== 'OTHERS'"
                                         :label="'Odrzucony'"
                                         :tooltip="slotProps.data.statusUpdateDate"
                                         :color="'#ff0000'"/>
                            <!-- QUERYTYPE OTHERS -->
                            <CustomBadge v-show="pendingTakeoverStores.has(slotProps.data.id)"
                                         :label="'Oczekuje na akceptację BŻ/OPL'"
                                         :tooltip="slotProps.data.statusUpdateDate"
                                         :color="'#959500'"/>
                            <CustomBadge v-show="rejectedTakeoverStores.has(slotProps.data.id)"
                                         :label="'Odrzucony / Odmowa BŻ/OPL'"
                                         :tooltip="slotProps.data.statusUpdateDate"
                                         :color="'#850000'"/>
                            <CustomBadge v-show="((activeStores.has(slotProps.data.id)
                                             || invitedStores.has(slotProps.data.id))
                                             && queryType === 'OTHERS')"
                                         :tooltip="slotProps.data.statusUpdateDate"
                                         :label="'Zajęty w zbiórce'"
                                         :color="'#850000'"/>
                        </template>
                    </div>
                </template>
            </Column>

            <Column v-for="(col, index) of selectedColumns" :field="col.field"
                    :header="col.header" :key="col.field + '_' + index"
                    :sortable="col.sortable" :style="col.style"></Column>

            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>

        <Dialog v-model:visible="displayCollectionStatusChangeDialog" header="Zmiana statusu sklepu"
                :modal="true" :dismissableMask="true" append-to=".wrapper" v-if="isMounted">
            <p>Powód zmiany statusu:</p>
            <CustomInputTextarea v-model="statusChangeData.reason"/>
            <Toolbar>
                <template v-slot:end>
                    <Button type="button" icon="pi pi-times" label="Anuluj"
                            @click="closeStatusChangeDialog"></Button>
                    <Button type="button" icon="pi pi-check" label="Zatwierdź"
                            @click="onCollectionStatusChange"></Button>
                </template>
            </Toolbar>
        </Dialog>

    </div>
    <div v-if="displayAll" style="display: flex; justify-content: right;">
        <p>Wyniki {{ stores.length > 0 ? 1 : 0 }} do {{ totalRecords }} z {{ totalRecords }}</p>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";
    import Dialog from "primevue/dialog";
    import Toolbar from "primevue/toolbar";
    import OverlayPanel from "primevue/overlaypanel";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomInputTextarea from "@/components/form/CustomInputTextArea";
    import {
        addStoresToCollectionUsingPOST as addStoresToCollection,
        removeStoresFromCollectionUsingPOST as removeStoresFromCollection,
        changeCollectionStatusUsingPOST as changeCollectionStatus,
        deleteCollectionStoreUsingDELETE as deleteStore,
        getRejectedStoresRequestedByUserUsingGET as getRejectedStoresRequestedByUser,
        getSimplifiedCollectionUsingGET as getSimplifiedCollection,
        getStoresRequestedByUserUsingGET as getStoresRequestedByUser,
        massChangeCollectionStatusesUsingPOST as massChangeCollectionStatuses,
        searchCollectionStoreCountUsingPOST as searchStoreCount,
        searchCollectionStoreUsingPOST as searchStore,
        getLoggedUserOrganisationUsingGET as getLoggedUserOrganisation,
        getSubordinateOrganisationsUsingGET as getSubordinateOrganisations,
        deleteCollectionStoresUsingPOST as deleteCollectionStores,
    } from "@/swagger/vue-api-client";
    import {ExportUtils} from "@/utils/ExportUtils";
    import {AddressUtils} from "@/utils/AddressUtils";
    import CustomBadge from "@/components/CustomBadge";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect";
    import {StoreStatusesUtils} from "@/utils/StoreStatusesUtils";
    import {AccessibilityTableMixin} from "@/mixins/AccessibilityTableMixin";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {ContextMenuMixin} from "@/mixins/ContextMenuMixin";

    export default {
        name: "CollectionStoreTable",

        components: {
            CustomMultiSelect,
            CustomBadge,
            CustomInputTextarea,
            Button,
            Column,
            DataTable,
            Dialog,
            Toolbar,
            OverlayPanel,
        },

        mixins: [AccessibilityTableMixin, ContextMenuMixin],

        props: {
            searchCriteria: {
                type: Object,
            },
            displayAll: {
                type: Boolean,
            },
            queryType: {
                type: String,
            },
            collectionId: {
                type: Number,
                default: null,
            },
            changingStatuses: {
                type: Boolean,
                default: false,
            },
            invitingStores: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:searchCriteria", "loaded", "sortSettingsChange", "selectionChange"],

        data() {
            return {
                loaded: false,
                loading: false,
                paginator: true,
                totalRecords: 0,
                stores: [],
                selectedStores: [],
                allStoresSelected: false,
                collectionStartedByFpbz: false,
                invitedStores: null,
                pendingTakeoverStores: null,
                rejectedTakeoverStores: null,
                activeStores: null,
                rejectedStores: null,
                storeCollectionStatusMapping: StoreStatusesUtils.storeCollectionStatusOfficialNameMapping,
                statusChangeData: this.getClearStatusChangeData(),
                displayCollectionStatusChangeDialog: false,
                searchParams: this.searchCriteria,
                Role: SystemRole,
                collectionStatusBadgeColor: {
                    SIGNED_UP: "#e0d847",
                    WAITING_FOR_STORE_CHAIN_DECISION: "green",
                    ACTIVE: "limegreen",
                },
                columns: [
                    {
                        field: "storeChain.name",
                        header: "Sieć sklepów",
                        sortable: true,
                        style: "{'min-width': '200px'}",
                    },
                    {
                        field: "name",
                        header: "Nazwa",
                        sortable: true,
                        style: "{'min-width': '200px'}",
                    },
                    {
                        field: "number", header: "Numer sklepu", sortable: true, style: "",
                    },
                    {
                        field: "address",
                        header: "Adres",
                        sortable: false,
                        style: "{'min-width': '200px'}",
                    },
                    {
                        field: "phoneNumber", header: "Nr telefonu", sortable: false, style: "",
                    },
                    {
                        field: "email", header: "Adres e-mail", sortable: true, style: "",
                    },
                    {
                        field: "assignedBz.name", header: "Przypisany BŻ", sortable: true, style: "",
                    },
                    {
                        field: "assignedOpl.name", header: "Przypisana OPL", sortable: true, style: "",
                    },
                ],
                selectedColumns: null,
                actionsMenuVisible: false,
                isMounted: false,
                userOrganisationId: null,
                oplAssignData: {
                    storeId: null,
                    organisationId: null,
                },
            };
        },

        computed: {
            BZCoordinatorCanSeeStatusHistory() {
                return this.Role.isBZCoordinator() && this.queryType === "OWN" && !this.changingStatuses;
            },
        },

        watch: {
            selectedColumns(value) {
                this.$cookies.set("collectionSelectedColumns", JSON.stringify(value));
            },
            searchCriteria(value) {
                this.searchParams = value;
            },

            displayAll(value) {
                this.paginator = !value;
                if (value) {
                    this.searchParams.page.limit = 999999;
                    this.searchParams.page.offset = 0;
                }
                this.onPage(this.getFirstPage(value), value);
            },

            queryType(value, oldValue) {
                if (value === oldValue) {
                    return;
                }

                this.searchParams.queryType = value;
                this.selectedStores = [];
                // TODO rethink this this.getBasicCollectionInfo();
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },

            selectedStores(value) {
                this.allStoresSelected = value.length === this.stores.length;

                this.$emit("selectionChange", value.length);
            },
        },

        created() {
            this.selectedColumns = JSON.parse(this.$cookies.get("collectionSelectedColumns")) || this.columns;
        },

        async mounted() {
            this.loading = true;

            await this.getBasicCollectionInfo();
            const response = await getLoggedUserOrganisation();
            this.userOrganisationId = response.data.id;

            this.searchParams.queryType = "OWN";

            this.emitter.on("changedContrast", this.handleContrastType);

            await this.$nextTick(() => {
                const currentContrastType = document.getElementsByClassName("wrapper")[0]
                    .getAttribute("data-contrast-type");
                this.handleContrastType(currentContrastType);

                this.$emit("loaded");
                this.loaded = true;
                this.loading = false;
            });
            this.isMounted = true;
        },

        methods: {

            canSeeOverlayPanel(store) {
                return (this.Role.isFPBZCoordinator()
                    && (store.collectionStatus === "SIGNED_UP"
                        || store.collectionStatus === "WAITING_FOR_STORE_CHAIN_DECISION"
                        || !this.collectionId))
                    || (this.Role.isBZCoordinator()
                        && store.assignedBz
                        && store.assignedBz.id === this.userOrganisationId
                    );
            },

            async getBasicCollectionInfo() {
                if (this.collectionId) {
                    try {
                        const collectionRequest = getSimplifiedCollection({id: this.collectionId});
                        const pendingTakeoverStoresRequest = getStoresRequestedByUser(
                            {collectionId: this.collectionId},
                        );
                        const rejectedTakeoverStoresRequest = getRejectedStoresRequestedByUser(
                            {collectionId: this.collectionId},
                        );

                        const responses = await Promise.all([
                            collectionRequest,
                            pendingTakeoverStoresRequest,
                            rejectedTakeoverStoresRequest,
                        ]);

                        this.collectionStartedByFpbz = responses[0].data.startedByFpbz;

                        this.invitedStores = new Set(responses[0].data.pendingStoresIds);
                        this.activeStores = new Set(responses[0].data.activeStoresIds);
                        this.rejectedStores = new Set(responses[0].data.rejectedStoresIds);

                        this.pendingTakeoverStores = new Set(responses[1].data);
                        this.rejectedTakeoverStores = new Set(responses[2].data);
                    } catch (error) {
                        if (error.response && error.response.status === 403) {
                            await this.$router.push({name: "forbidden"});
                        } else {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                            });
                        }
                    }
                }
            },

            handleContrastType(color) {
                if (color === "black") {
                    this.collectionStatusBadgeColor = {
                        SIGNED_UP: "black",
                        WAITING_FOR_STORE_CHAIN_DECISION: "black",
                        ACTIVE: "black",
                    };
                } else if (color === "yellow") {
                    // TODO: handle this case
                    this.collectionStatusBadgeColor = {
                        SIGNED_UP: "#e0d847",
                        WAITING_FOR_STORE_CHAIN_DECISION: "green",
                        ACTIVE: "limegreen",
                    };
                } else {
                    this.collectionStatusBadgeColor = {
                        SIGNED_UP: "#e0d847",
                        WAITING_FOR_STORE_CHAIN_DECISION: "green",
                        ACTIVE: "limegreen",
                    };
                }
            },

            /**
             * FIXME: remove once DataTable:row-unselect-all starts to be emitted reliably
             * when a paginator with more than 1 page is present
             */
            onSelectAll() {
                if (this.allStoresSelected) {
                    this.selectedStores = [];
                }
            },

            async search() {
                this.selectedStores = [];
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
                await this.getBasicCollectionInfo();
            },

            onPage(event, fakeCall = false) {
                this.updateTotalRecords();
                this.loading = true;

                if (!fakeCall) {
                    this.$cookies.set("pageLimit", event.rows, "365d");
                }

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                if (!event.fakeEvent || (event.fakeEvent && event.sortField)) {
                    this.searchParams.page.sortField = event.sortField;
                }
                if (!event.fakeEvent || (event.fakeEvent && event.sortOrder)) {
                    this.searchParams.page.sortOrder = event.sortOrder;
                }

                searchStore({searchCriteria: this.searchParams}).then((response) => {
                    this.stores = response.data;

                    this.stores.forEach((store) => {
                        store.address = AddressUtils.generateAddressString(store);
                    });

                    this.loading = false;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            onSort(event) {
                this.onPage(event, this.displayAll);
                this.$emit("sortSettingsChange");
            },

            updateTotalRecords() {
                searchStoreCount({searchCriteria: this.searchParams}).then((response) => {
                    this.totalRecords = response.data;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            getFirstPage(fakeCall = false) {
                const pageLimit = parseInt(this.$cookies.get("pageLimit"), 10);
                if (!fakeCall && pageLimit) {
                    this.searchParams.page.limit = pageLimit;
                }

                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    fakeEvent: true,
                };
            },

            deleteStore(storeId, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno usunąć sklep?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        deleteStore({id: storeId}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie usunięto sklep",
                            });
                            this.search();
                        }).catch((error) => {
                            if (error.response && error.response.status === 409) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: error.response.data,
                                });
                            } else if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            deleteStores() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: " Czy na pewno usunąć wybrane sklepy?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        deleteCollectionStores({
                            storeIdListRequest: {
                                idList: this.selectedStores.map((store) => store.id),
                            },
                        }).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie usunięto sklepy",
                            });
                            this.search();
                        }).catch((error) => {
                            if (error.response && error.response.status === 409) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: error.response.data,
                                });
                            } else if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            onSetStoreCollectionStatus(storeId, collectionStatus) {
                this.statusChangeData.storeId = storeId;
                this.statusChangeData.status = collectionStatus;

                this.displayCollectionStatusChangeDialog = true;
            },

            onCollectionStatusChange() {
                if (this.statusChangeData.reason === "") {
                    this.statusChangeData.reason = null;
                }

                const storeCollectionStatusChangeRequest = {...this.statusChangeData};
                storeCollectionStatusChangeRequest.collectionId = this.$route.params.id;

                changeCollectionStatus({storeCollectionStatusChangeRequest}).then(() => {
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: "Pomyślnie zmieniono status sklepu",
                    });

                    if (this.statusChangeData.status === "IDLE") {
                        this.stores = this.stores.filter((store) => store.id !== this.statusChangeData.storeId);
                        return;
                    }

                    if (this.statusChangeData.status === "REJECTED") {
                        this.rejectedStores.add(this.statusChangeData.storeId);
                        this.invitedStores.delete(this.statusChangeData.storeId);
                        this.activeStores.delete(this.statusChangeData.storeId);
                    }

                    if (this.statusChangeData.status === "RESTORED") {
                        this.activeStores.add(this.statusChangeData.storeId);
                        this.invitedStores.delete(this.statusChangeData.storeId);
                        this.rejectedStores.delete(this.statusChangeData.storeId);
                    }

                    this.stores[
                        this.stores.findIndex((store) => store.id === this.statusChangeData.storeId)
                    ].collectionStatus = this.statusChangeData.status;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                }).finally(() => {
                    this.closeStatusChangeDialog();
                });
            },

            callMassCollectionStatusChange(statusChangeData) {
                statusChangeData.storeIds = this.selectedStores.map((store) => store.id);
                statusChangeData.collectionId = this.collectionId;

                massChangeCollectionStatuses({massCollectionStatusChangeRequest: statusChangeData}).then(() => {
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: "Pomyślnie zmieniono statusy sklepów",
                    });

                    // this.selectedStores.forEach((st) => {
                    //     this.stores[
                    //         this.stores.findIndex((store) => store.id === st.id)
                    //     ].collectionStatus = statusChangeData.newStatus;
                    // });

                    this.search();
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            closeStatusChangeDialog() {
                this.statusChangeData = this.getClearStatusChangeData();
                this.displayCollectionStatusChangeDialog = false;
            },

            getClearStatusChangeData() {
                return {
                    storeId: null,
                    status: null,
                    reason: null,
                };
            },

            onRemoveStoresFromCollection(store = null) {
                const collectionRemoveStoresRequest = {id: this.collectionId};
                if (store === null) {
                    collectionRemoveStoresRequest.storeIds = [...this.selectedStores].map((item) => item.id);
                } else {
                    collectionRemoveStoresRequest.storeIds = [store.id];
                }
                removeStoresFromCollection({collectionRemoveStoresRequest}).then(() => {
                    const ownMessage = "Pomyślnie usunięto sklepy ze zbiórki";
                    const othersMessage = "Pomyślnie cofnięto prośby o przejęcie sklepów";
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: this.queryType === "OWN" ? ownMessage : othersMessage,
                    });

                    switch (this.queryType) {
                        case "OWN":
                            if (store === null) {
                                this.selectedStores.forEach((st) => {
                                    this.invitedStores.delete(st.id);
                                    this.activeStores.delete(st.id);
                                    this.stores[this.stores.findIndex((s) => s.id === st.id)]
                                        .collectionStatus = "IDLE";
                                });
                            } else {
                                this.invitedStores.delete(store.id);
                                this.activeStores.delete(store.id);
                                this.stores[this.stores.findIndex((s) => s.id === store.id)]
                                    .collectionStatus = "IDLE";
                            }
                            break;
                        case "OTHERS":
                            if (store === null) {
                                this.selectedStores.forEach((st) => this.pendingTakeoverStores.delete(st.id));
                            } else {
                                this.pendingTakeoverStores.delete(store.id);
                            }
                            break;
                        default:
                            break;
                    }
                }).catch((error) => {
                    if (error.response && error.response.status === 400) {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Usunięcie sklepów nie powiodło się",
                        });
                    } else if (error.response && error.response.status === 409) {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: error.response.data,
                        });
                    } else {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });
                    }
                });
            },

            onAddStoresToCollection(store = null) {
                const collectionInvitationRequest = {id: this.collectionId};
                if (store === null) {
                    collectionInvitationRequest.storeIds = [...this.selectedStores].map((item) => item.id);
                } else {
                    collectionInvitationRequest.storeIds = [store.id];
                }

                addStoresToCollection({collectionInvitationRequest}).then(() => {
                    const unassignedMessage = "Pomyślnie przypisano sklepy";
                    const otherMessage = "Pomyślnie przesłano prośbę o zaproszenie sklepów do zbiórki.";
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: this.queryType === "UNASSIGNED" ? unassignedMessage : otherMessage,
                    });

                    switch (this.queryType) {
                        case "OWN":
                            if (store === null) {
                                this.selectedStores.forEach((st) => {
                                    this.invitedStores.add(st.id);
                                    this.stores[this.stores.findIndex((s) => s.id === st.id)]
                                        .collectionStatus = "SIGNED_UP";
                                    this.rejectedStores.delete(st.id);
                                });
                            } else {
                                this.invitedStores.add(store.id);
                                this.stores[this.stores.findIndex((s) => s.id === store.id)]
                                    .collectionStatus = "SIGNED_UP";
                                this.rejectedStores.delete(store.id);
                            }

                            break;
                        case "OTHERS":
                            if (store === null) {
                                this.selectedStores.forEach((st) => {
                                    this.pendingTakeoverStores.add(st.id);
                                    this.rejectedTakeoverStores.delete(st.id);
                                    this.rejectedStores.delete(st.id);
                                });
                            } else {
                                this.pendingTakeoverStores.add(store.id);
                                this.rejectedTakeoverStores.delete(store.id);
                                this.rejectedStores.delete(store.id);
                            }

                            break;
                        case "UNASSIGNED": {
                            const stores = store === null ? this.selectedStores : [store];
                            const storeIds = new Set(stores.map((st) => st.id));
                            this.stores = this.stores.filter((st) => !storeIds.has(st.id));
                            this.invitedStores = new Set([...this.invitedStores, ...storeIds]);

                            break;
                        }
                        default:
                            break;
                    }
                }).catch((error) => {
                    if (error.response && error.response.status === 400) {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Zaproszenie sklepów nie powiodło się",
                        });
                    } else if (error.response && error.response.status === 409) {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: error.response.data,
                        });
                    } else {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });
                    }
                });
            },

            exportXLSX(allData) {
                if (allData) {
                    this.loading = true;
                    searchStore({
                        searchCriteria: this.removePagingFromSearchCriteria(),
                    }).then((response) => {
                        const responseData = response.data;
                        responseData.forEach((store) => {
                            store.address = AddressUtils.generateAddressString(store);
                        });
                        ExportUtils.exportXLSX(responseData
                            .map(this.collectionStoreSearchMapper()), "Sklepy", this.getExportFileName());
                        this.loading = false;
                    }).catch(() => {
                        this.loading = false;
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });
                    });
                } else {
                    ExportUtils.exportXLSX(this.selectedStores
                        .map(this.collectionStoreSearchMapper()), "Sklepy", this.getExportFileName());
                }
            },

            collectionStoreSearchMapper() {
                /* eslint-disable quote-props */
                /* eslint-disable dot-notation */
                return (store) => {
                    const storeData = {};

                    // exported store id needed for collection status change import
                    if (this.collectionId && this.changingStatuses) {
                        storeData["Id"] = store.id;
                    }

                    // always present fields
                    storeData["Status"] = this.translateStoreStatus(store.collectionStatus);

                    // extra fields selectable by user
                    this.selectedColumns.forEach((column) => {
                        if (column.field === "storeChain.name") {
                            storeData["Sieć sklepów"] = store.storeChain.name;
                            return;
                        }
                        if (column.field === "assignedBz.name") {
                            storeData["Przypisany BŻ"] = store.assignedBz?.name;
                            return;
                        }
                        if (column.field === "assignedOpl.name") {
                            storeData["Przypisana OPL"] = store.assignedOpl?.name;
                            return;
                        }

                        // if (column.field.includes("assigned")) {
                        //     // assigned BZ/OPL - extract the object's name
                        //     const fieldName = "assigned"
                        //         +
                        //         + column.field.slice(8).split(".")[0]; // "Bz" or "Opl"
                        //     storeData[column.header] = store[fieldName] ? store[fieldName].name : "-";
                        //
                        //     return;
                        // }

                        if (column.field === "address") {
                            storeData["Województwo"] = store.voivodeship;
                            storeData["Miasto"] = store.city;
                            storeData["Ulica"] = store.street;
                            storeData["Numer domu"] = store.houseNumber;
                            storeData["Numer mieszkania"] = store.apartmentNumber;
                            storeData["Kod pocztowy"] = store.postalCode;

                            return;
                        }

                        // text fields
                        storeData[column.header] = store[column.field];
                    });

                    return storeData;
                };
            },

            removePagingFromSearchCriteria() {
                const copy = JSON.parse(JSON.stringify(this.searchCriteria));
                copy.queryType = "OWN";
                copy.page = {
                    limit: 999999,
                    offset: 0,
                    sortField: null,
                    sortOrder: null,
                };
                return copy;
            },

            canAddToCollection(store) {
                switch (this.queryType) {
                    case "OWN":
                        return ["IDLE"].includes(store.collectionStatus);
                    case "OTHERS":
                        return !this.pendingTakeoverStores.has(store.id) && !this.activeStores.has(store.id)
                            && !this.invitedStores.has(store.id)
                            && (this.rejectedTakeoverStores.has(store.id)
                                || ["IDLE", "SIGNED_UP"].includes(store.collectionStatus));
                    case "UNASSIGNED":
                        return true;
                    default:
                        return false;
                }
            },

            canRemoveFromCollection(store) {
                switch (this.queryType) {
                    case "OWN":
                        return ["SIGNED_UP"].includes(store.collectionStatus)
                            && (!this.rejectedStores || !this.rejectedStores.has(store.id));
                    case "OTHERS":
                        return this.pendingTakeoverStores.has(store.id);
                    case "UNASSIGNED":
                        return false;
                    default:
                        return false;
                }
            },

            translateStoreStatus(storeStatus) {
                switch (storeStatus) {
                    // collectionStatus
                    case "IDLE":
                        return "POZA ZBIÓRKĄ";
                    case "SIGNED_UP":
                        return "ZGŁOSZONY";
                    case "WAITING_FOR_STORE_CHAIN_DECISION":
                        return "WYSŁANY DO SIECI";
                    case "ACTIVE":
                        return "AKTYWOWANY";
                    case "REJECTED":
                        return "ODRZUCONY";
                    case "RESTORED":
                        return "PRZYWRÓCONY";

                    // unspecified
                    default:
                        return "";
                }
            },

            getExportFileName() {
                return "Dane sklepów";
            },

            toggleActionsMenu(event, slotProps) {
                this.$refs["actionsMenu" + slotProps.data.id].toggle(event);
            },

            getSubordinateOrganisations,
        },
    };
</script>

<style lang="less" scoped>
.invited {
    font-weight: bold;
    color: #38bb38;
}

.pending-invite {
    font-weight: bold;
    color: #bebe00;
}

.pending-takeover {
    font-weight: bold;
    color: #959500;
}

.rejected-takeover {
    font-weight: bold;
    color: #850000;
}

.active {
    font-weight: bold;
    color: #40ff00;
}

.rejected {
    font-weight: bold;
    color: #ff0000;
}
</style>
