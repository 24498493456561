/* eslint-disable */

import XLSX from "xlsx";
import {FileUtils} from "@/utils/FileUtils";

export const ExportUtils = {

    exportXLSX(data, sheetName, name) {
        const sheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, sheet, sheetName);

        XLSX.writeFile(workbook, generateFilename(name, true));
    },
};

function generateFilename(name, isXLSX) {
    const timestamp = (new Date()).toISOString()
        .match(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).*/)
        .slice(1)
        .join("");

    return name + " - " + timestamp + (isXLSX ? ".xlsx" : ".csv");
}
